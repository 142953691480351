import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { thunk } from "redux-thunk";
import { generalReducer } from "./general/general-reducers";
import layoutReducer from "../layout/sidebar/sidebar-store/reducers";
import { broadcastMiddleware, setupBroadcastListener } from "./broadcast/broadcastMiddleware";
import { RESET_STORE } from "./general/constants";
import { ProfileReducer } from "./harmoni-profile/profile-reducer";
import { HARMONI_ROOT_REDUCER } from "../container/harmoni-panel/store/harmoni-root-reducer";
import { PANELS } from "../config";


// Define the panel-specific reducers
const PANEL_REDUCERS = {
  [PANELS.HARMONI]: HARMONI_ROOT_REDUCER,
  // Future panels can be added here, e.g.,
  // [PANELS.CORPORATE]: CORPORATE_ROOT_REDUCER,
  // [PANELS.PARTNER]: PARTNER_ROOT_REDUCER,
};

// Base reducers used across all panels
const baseReducers = {
  general: generalReducer,
  layout: layoutReducer,
  profile: ProfileReducer,
};

// Function to create the combined reducers dynamically
const createAppReducer = (currentPanel) =>{
  return combineReducers({
    ...baseReducers,
    ...(PANEL_REDUCERS[currentPanel] || {}), // Add panel-specific reducers if available
  });
} 

let appReducer = createAppReducer(null); // Start with no panel-specific reducers

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined; // Reset the state
  }
  return appReducer(state, action);
};

// Define composeEnhancers
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the store
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, broadcastMiddleware)));

// Setup broadcast listener
setupBroadcastListener(store);

// Function to update reducers based on the current panel
let currentPanel = null; // Track the current panel state
store.subscribe(() => {
  const state = store.getState();
  const newPanel = state.general?.loggedInPanel;

  if (newPanel !== currentPanel) {
    currentPanel = newPanel;

    appReducer = createAppReducer(currentPanel); // Update reducers based on the panel

    // Replace the root reducer dynamically
    store.replaceReducer((state, action) => {
      if (action.type === RESET_STORE) {
        state = undefined;
      }
      return appReducer(state, action);
    });
  }
});


export default store;
