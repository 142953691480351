import {
  SET_PARTNER_TOTAL_RECORDS,
  SET_PARTNER_PAGE,
  EDIT_PARTNER,
  ADD_PARTNER,
  DELETE_PARTNER,
  PARTNER_LOADING,
  PARTNER_FAILURE,
  GET_PARTNER,
  SET_PARTNER_REDIRECT_URL,
} from "./partner-constant";
import {
  handleLoading,
  handleFailure,
  handleSetRedirectUrl,
  handleGetData,
  handleSetTotalRecords,
  handleSetPage,
  handleEditItem,
  handleAddItem,
  handleDeleteItems,
} from "../utils/reducerUtils";

// Initial state
const initialState = {
  partnerList: {},
  totalRecords: 0,
  totalPages: 0,
  currentPage: 1,
  loading: false,
  error: null,
  redirectTo: false,
};

export const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_LOADING:
      return handleLoading(state, action);

    case PARTNER_FAILURE:
      return handleFailure(state, action);

    case SET_PARTNER_REDIRECT_URL:
      return handleSetRedirectUrl(state, action);

    case GET_PARTNER:
      return handleGetData(state, action, "partnerList");

    case SET_PARTNER_TOTAL_RECORDS:
      return handleSetTotalRecords(state, action);

    case SET_PARTNER_PAGE:
      return handleSetPage(state, action);

    case EDIT_PARTNER:
      return handleEditItem(state, action, "partnerList", "partner_id");

    case ADD_PARTNER:
      return handleAddItem(state, action, "partnerList", "partner_id");

    case DELETE_PARTNER:
      return handleDeleteItems(state, action, "partnerList", "partner_id");

    default:
      return state;
  }
};
