import {
  SET_BRANCH_TOTAL_RECORDS,
  SET_BRANCH_PAGE,
  EDIT_BRANCH,
  ADD_BRANCH,
  DELETE_BRANCH,
  BRANCH_LOADING,
  BRANCH_FAILURE,
  GET_BRANCH,
  SET_BRANCH_REDIRECT_URL,
} from "./branch-constant";
import {
  handleLoading,
  handleFailure,
  handleSetRedirectUrl,
  handleGetData,
  handleSetTotalRecords,
  handleSetPage,
  handleEditItem,
  handleAddItem,
  handleDeleteItems,
} from "../utils/reducerUtils";

// Initial state
const initialState = {
  branchList: {},
  totalRecords: 0,
  totalPages: 0,
  currentPage: 1,
  loading: false,
  error: null,
  redirectTo: false,
};

export const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANCH_LOADING:
      return handleLoading(state, action);

    case BRANCH_FAILURE:
      return handleFailure(state, action);

    case SET_BRANCH_REDIRECT_URL:
      return handleSetRedirectUrl(state, action);

    case GET_BRANCH:
      return handleGetData(state, action, "branchList");

    case SET_BRANCH_TOTAL_RECORDS:
      return handleSetTotalRecords(state, action);

    case SET_BRANCH_PAGE:
      return handleSetPage(state, action);

    case EDIT_BRANCH:
      return handleEditItem(state, action, "branchList", "branch_id");

    case ADD_BRANCH:
      return handleAddItem(state, action, "branchList", "branch_id");

    case DELETE_BRANCH:
      return handleDeleteItems(state, action, "branchList", "branch_id");

    default:
      return state;
  }
};
