export const SET_BRANCH_PAGE_DATA = 'SET_BRANCH_PAGE_DATA';
export const SET_BRANCH_TOTAL_RECORDS = 'SET_BRANCH_TOTAL_RECORDS';
export const SET_BRANCH_PAGE = 'SET_BRANCH_PAGE';
export const EDIT_BRANCH = 'EDIT_BRANCH';
export const ADD_BRANCH = 'ADD_BRANCH';
export const DELETE_BRANCH= 'DELETE_BRANCH';
export const BRANCH_LOADING = 'BRANCH_LOADING';
export const BRANCH_FAILURE = 'BRANCH_FAILURE';
export const GET_BRANCH= 'GET_BRANCH';
export const SET_BRANCH_REDIRECT_URL = 'SET_BRANCH_REDIRECT_URL';


export const BRANCH_PROGRESS_TYPE={
    GET_BRANCH_LIST:"GET_BRANCH_LIST",
    ADD_BRANCH_PROGRESS:"ADD_BRANCH_PROGRESS",
    EDIT_BRANCH_PROGRESS:"EDIT_BRANCH_PROGRESS",
    DELETE_BRANCH_PROGRESS:"DELETE_BRANCH_PROGRESS"
}

