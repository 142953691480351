export const SET_PARTNER_PAGE_DATA = 'SET_PARTNER_PAGE_DATA';
export const SET_PARTNER_TOTAL_RECORDS = 'SET_PARTNER_TOTAL_RECORDS';
export const SET_PARTNER_PAGE = 'SET_PARTNER_PAGE';
export const EDIT_PARTNER = 'EDIT_PARTNER';
export const ADD_PARTNER = 'ADD_PARTNER';
export const DELETE_PARTNER= 'DELETE_PARTNER';
export const PARTNER_LOADING = 'PARTNER_LOADING';
export const PARTNER_FAILURE = 'PARTNER_FAILURE';
export const GET_PARTNER= 'GET_PARTNER';
export const SET_PARTNER_REDIRECT_URL = 'SET_PARTNER_REDIRECT_URL';


export const PARTNER_PROGRESS_TYPE={
    GET_PARTNER_LIST:"GET_PARTNER_LIST",
    ADD_PARTNER_PROGRESS:"ADD_PARTNER_PROGRESS",
    EDIT_PARTNER_PROGRESS:"EDIT_PARTNER_PROGRESS",
    DELETE_PARTNER_PROGRESS:"DELETE_PARTNER_PROGRESS"
}

